import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: localStorage.getItem('accessToken') || '',
  },
  getters: {
  },
  mutations: {
    // token
    SAVE_ACCESSTOKEN(state, token) {
      localStorage.setItem('accessToken', token)
      state.accessToken = token
    },
  },
  actions: {
  },
  modules: {
  }
})
