import request from './request'
const { post } = request


// 获取验证码
const getPhoneCode = (param) => post('/api/user/getMobileCode', param)
// 注册
const appRegister = (param) => post('/api/oauth/appRegister', param)
// 校验
const checkVersionNew = (param) => post('/api/index/checkVersionNew', param)


export default {
  getPhoneCode,
  appRegister,
  checkVersionNew
  }