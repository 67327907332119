import axios from 'axios'
import store from '../store/index.js'
import router from '../router/index.js'
import { Toast } from 'vant';
const url = location.origin
//  const url='http://ycwww.360thg.com'

const isProd = /360thg.com|localhost|file/.test(url),
    test = /test/.test(url),
    // URL = 'https://rpapi-qa.yxpanda.com/' //测试环境
    URL =   isProd ? 'https://rpapi-qa.yxpanda.com/' :  'https://rpapi.yxpanda.com/'//生产环境
//    URL =  url+'/api';

var ua = navigator.userAgent.toLowerCase();
var isWeixin = ua.indexOf('micromessenger') != -1;
var isAlipay = ua.indexOf('alipayclient') != -1;
const isProd2 = /360thg.com|localhost|file/.test(location.host),
    redirect_uri = isProd2 ? 'http://xtth5.360thg.com' : 'https://h5.xititi.com';

axios.defaults.baseURL = URL
axios.defaults.timeout = 20000

axios.interceptors.request.use(config => {

    const accessToken = window.localStorage.getItem('accessToken')


    if (accessToken) {
        config.headers['token'] = accessToken
    }

    config.headers['platform'] = 7
    //  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

    return config
})

axios.interceptors.response.use(response => response, error => {

    if (error.response && error.response.status) {

        error.message = error.response.data.message


    }
    return Promise.reject(error)
})
let configs;

function getToken(obj) {
    const config = {
        method: 'post',
        url: '/api/getToken'
    }
    new Promise((resolve, reject) => {
        axios(config).then(res => {
            if (res.data.code == 200) {
                store.commit("SAVE_ACCESSTOKEN", res.data.data);
                handle(configs,obj)
            } else {
                Toast(res.data.msg || res.data.message);
            }

            resolve()
        }).catch(err => {
            reject(err)
        })
    })
}
function handle(config,obj=null) {
    return new Promise((resolve, reject) => {
        configs = config
        axios(config).then(res => {
            if (res.data.code == -1 || res.data.code == -2) {
                getToken(resolve);
                return false;
            }

            if (obj !== null){
                obj(res.data)
            }
            resolve(res.data)
        }).catch(err => {
            console.log(err)

            reject(err)
        })
    })
}

const post = (url, params) => {
    const config = {
        method: 'post',
        url
    }
    if (params) {
        config.data = params
    }

    return handle(config)
}

const get = (url, params) => {
    const config = {
        method: 'get',
        url
    }
    if (params) {
        config.params = params
    }
    return handle(config)
}


export default {
    post,
    get,
}