import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ajax from './utils/getData.js'
import utils from './utils/formatDate.js'
import 'amfe-flexible'
import './utils/vant.js'

Vue.prototype.$ajax = ajax
Vue.prototype.$utils = utils
const isProd = /360thg|localhost|file/.test(location.host),
    test = /360thg/.test(location.host),
    URL =  isProd ? location.origin : 'https://rpofficial.yxpanda.com';
Vue.prototype.$static_url =URL;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
