<template>
  <div class="page">
    <div class="title">注册账号</div>
    <div class="logo">
      <img
        src="https://yxhb.oss-cn-zhangjiakou.aliyuncs.com/20230426/974988c41356192076f75c5162d7e48b.png"
        alt=""
      />
    </div>
    <div class="body">
      <div class="introd">手机号</div>
      <div class="frame">
        <input
          type="number"
          oninput="if(value.length>11) value=value.slice(0,11)"
          v-model="phone"
          placeholder="请输入您注册的手机号* "
        />
        <div class="code" v-if="codeText == '请输入验证码'" @click="obtainCode">
          获取验证码
        </div>
        <div class="code" v-else>{{ codeText }}</div>
      </div>
      <div class="introd">验证码</div>
      <div class="frame">
        <input type="tel" v-model="code" placeholder="请输入短信验证码*" />
      </div>
      <div class="introd">登录密码</div>
      <div class="frame">
        <input
          type="text"
          maxlength="36"
          v-model="password"
          placeholder="请输入6-32位登录密码*"
        />
      </div>
      <div class="introd">确认密码</div>
      <div class="frame">
        <input type="text" v-model="check" placeholder="请再次输入登录密码*" />
      </div>
      <div class="introd">绑定邀请人</div>
      <div class="binding">邀请人一经绑定，不可修改</div>
      <div class="frame">
        <input
          type="text"
          v-model="invite"
          placeholder="请输入邀请人手机号码  "
        />
      </div>
    </div>
    <div class="footer" @click="register">注册</div>
    <div class="end" @click="topage">下载APP</div>
    <div style="height:98px"></div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      phone: "", //手机号码
      code: "", //验证码
      password: "", //密码
      check: "", //校验验证码
      invite: "", //邀请人手机号码
      codeText: "请输入验证码",
    };
  },

  methods: {
    // 获取验证码按钮
    obtainCode() {
      if (this.phone == "") {
        this.$toast("请先输入手机号");
      } else if (this.phone.length != 11) {
        this.$toast("请输入正确的手机号");
      } else {
        // 倒计时
        this.getPhoneCode();
        let maxNum = 59;
        let oldCodeText = this.codeText;
        this.codeText = `${maxNum}s重新发送`; //初始显示倒计时
        let codeCountDown = setInterval(() => {
          this.disabled = true;
          let countDownNum = maxNum--;
          this.codeText = `${countDownNum}s重新发送`;
          if (countDownNum == 0) {
            //倒计时结束
            this.codeText = oldCodeText;
            clearInterval(codeCountDown);
            this.disabled = false;
          }
        }, 1000);
      }
    },

    // 获取验证码接口
    getPhoneCode() {
      this.$ajax
        .getPhoneCode({
          phone: this.phone,
          type:2
        })
        .then((res) => {
         this.$toast(res.msg)
        });
    },

    // 注册
    register() {
      if (this.phone == "") {
        this.$toast("请输入手机号");
      } else if (this.phone.length != 11) {
        this.$toast("请输入正确的手机号");
      } else if (this.code == "") {
        this.$toast("验证码不能为空");
      } else if (this.password == "") {
        this.$toast("请填写登录密码");
      } else if (this.password.length < 6) {
        this.$toast("密码不能少于6位");
      } else if (this.password != this.check) {
        this.$toast("两次输入的密码不一致");
      } else if (this.invite != "" && this.invite.length != 11) {
        this.$toast("请输入正确的邀请人手机号");
      } else {
        this.appRegister();
      }
    },

    // 注册的接口
    appRegister() {
      this.$ajax
        .appRegister({
          phone: this.phone,
          code: this.code,
          password: this.password,
          refer_phone: this.invite,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toast("注册成功");
            this.phone = "";
            this.code = "";
            this.password = "";
            this.check = "";
            this.invite = "";
          } else {
            this.$toast(res.msg);
          }
        });
    },

    // 下载App跳转
    topage() {
      this.$router.push("/download");
    },
  },

  created() {},
};
</script>
<style scoped>
.page {
  background-color: #f4f4f4;
}
/* scoped-样式只在本组件使用 */
.title {
  width: 750px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 60px
}
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 202px;
  height: 212px;
  margin: 0 auto;
}

.body {
  padding: 24px;
  box-sizing: border-box;
  width: 694px;
  height: 830px;
  background: #ffffff;
  box-shadow: 0px 8px 4px 0px rgba(168, 168, 168, 0.12);
  border-radius: 32px;
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.introd {
  font-size: 28px;
  font-weight: 500;
  color: #333;
  line-height: 33px;
  margin-bottom: 10px;
}

.frame {
  width: 646px;
  height: 88px;
  background: #f4f4f4;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  margin-bottom: 20px;
  justify-content: space-between;
    position: relative;
}

.frame:last-child {
  margin: 0;

}

.frame input {
  border: none;
  background: #f4f4f4;
  outline: none;
  height: 40px;
  font-size: 28px;
 width: 100%;
}

.code {
  font-size: 28px;
  color: #ea3f49;
  font-weight: 500;
  position: absolute;
  right: 24px;
}

.binding {
  font-size: 22px;
  color: #f19d5b;
  font-weight: 400;
  margin-bottom: 10px;
}

.footer {
  width: 678px;
  height: 96px;
  background: #ea3f49;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 38px;
  font-size: 32px;
  margin: 0 auto 40px;
}

.end {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 500;
  color: #ea3f49;
  /* margin-bottom: 98px; */
}

</style>