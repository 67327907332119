<template>
  <div>
    <div class="top">
      <img
        @click="circle"
        class="return"
        src="https://yxhb.oss-cn-zhangjiakou.aliyuncs.com/20230427/9f7f676c96c4268987903f97aabc708e.png"
        alt=""
      />
      <div class="title">喜提提</div>
    </div>
    <div class="body">
      <img
        class="logo"
        src="https://yxhb.oss-cn-zhangjiakou.aliyuncs.com/20230427/c90d21fadbed067308438b5f46f9c97e.png"
        alt=""
      />
      <img
        class="iphone"
        src="https://yxhb.oss-cn-zhangjiakou.aliyuncs.com/20230427/94669315cefb33ebe7543eb621ce3d1b.png"
        alt=""
      />
      <div class="small" @click="androidDownLoad">
        <img
          src="https://yxhb.oss-cn-zhangjiakou.aliyuncs.com/20230427/3bcb6cbe1d0b6fc652c436a10dafaf41.png"
          alt=""
        />
        Android下载
      </div>
      <div class="small" @click="iosDownLoad">
        <img
          src="https://yxhb.oss-cn-zhangjiakou.aliyuncs.com/20230427/dde749d9a99169358e562fcac88c8230.png"
          alt=""
        />
        iOS下载
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "download",
  data() {
    return {
      parameter: {
        andriod:'',
        ios:''
      }, //接口信息
    };
  },

  methods: {
    // 安卓下载
    androidDownLoad() {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf("micromessenger") > -1) {
        this.$toast("可在浏览器打开此网页来下载文件");
      } else {
        // const a = document.createElement("a");
        // console.log(a);
        // // console.log(this.parameter.android);
        // a.href = this.parameter.andriod;
        var src = this.parameter.andriod;
        var form = document.createElement('form');
        form.action = src;
        document.getElementsByTagName('body')[0].appendChild(form);
        form.submit();
      }
    },

    // ios下载
    iosDownLoad() {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf("micromessenger") > -1) {
        this.$toast("可在浏览器打开此网页来下载文件");
      } else {
        window.open(this.parameter.ios, "_blank");
      }
    },

    // 校验接口
    checkVersionNew() {
      this.$ajax
        .checkVersionNew({
          cur_version: "v1.0.0",
        })
        .then((res) => {
            this.parameter = res.data.url;
        });
    },

    circle() {
      this.$router.push("/");
    },
  },

  created() {
    this.checkVersionNew();
  },
};
</script>
<style scoped>
/* scoped-样式只在本组件使用 */
.top {
  width: 750px;
  height: 88px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.return {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 20px;
  left: 24px;
}

.title {
  font-size: 34px;
  font-weight: 500;
}

.body {
  background: url(https://yxhb.oss-cn-zhangjiakou.aliyuncs.com/20230427/3404162ed67bf786dd0e0d5dbd6a4d9c.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 88px;
  bottom: 0px;
}

.logo {
  width: 324px;
  height: 96px;
  margin: 84px 0 36px;
}

.iphone {
  width: 340px;
  height: 688px;
  margin-bottom: 64px;
}

.small {
  width: 478px;
  height: 84px;
  background: #ffe7e7;
  border-radius: 16px;
  border: 2px solid #ea3f49;
  font-size: 32px;
  color: #ea3f49;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 44px;
}

.small img {
  width: 28px;
  height: 32px;
  margin-right: 12px;
}
</style>